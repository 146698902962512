import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/silent-night-deadly-night-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1984 christmas slasher movie, Silent Night, Deadly Night"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 28</h1>
                    <h2>Silent Night, Deadly Night</h2>
                    <h3>December 11, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Charles Sellier // <b>Starring:</b> Robert Brian Wilson &amp; Lilyan Chauvin</p>
                        <div className="player">
                            <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/28-Silent-Night--Deadly-Night-wguest-Joe-Vallese-e2d3bgs" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe horror podcast episode 28, Silent Night, Deadly Night"></iframe>
                        </div>
                        <p><Link to="/transcripts/silent-night-deadly-night">Read the episode transcript</Link></p>
                        <p>This week, Bryan and Dave are joined by author, Joe Vallese, and hope that Santa will judge them to be good because they definitely don't want to get on his bad side as they dive head first into 1984's Santa slasher movie, Silent Night, Deadly Night. The movie that went too far. The movie that put the chill on 80's slasher movies for a couple of years. The movie that parent groups organized and protested against. Did it deserve such treatment? Not really but what's America good for if not full-blown moral panics in the face of imaginary monsters.</p>
                        <p>Trailer: <OutboundLink href="https://www.youtube.com/watch?v=hNBJfv5pIdY" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=hNBJfv5pIdY</OutboundLink></p>
                        <p>It Came From The Closet: Queer Reflections on Horror, edited by Joe Vallese: <OutboundLink href="https://amzn.to/3tdToWu" target="_blank" rel="noreferrer">https://amzn.to/3tdToWu</OutboundLink></p>
                        <p>Joe Vallese on Instagram: <OutboundLink href="https://www.instagram.com/it_came_from_the_closet/" target="_blank" rel="noreferrer">https://www.instagram.com/it_came_from_the_closet/</OutboundLink></p>
                        <p>Joe Vallese on Twitter: <OutboundLink href="https://twitter.com/homohorror" target="_blank" rel="noreferrer">https://twitter.com/homohorror</OutboundLink></p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/2023-roundup">Next Episode</Link></li>
                        <li><Link to="/episodes/hellraiser">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)